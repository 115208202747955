import React, { useState, useRef, useEffect, Fragment } from 'react'
import { useSpring, animated, useTrail } from 'react-spring'
import { ArrowProps } from 'react-multi-carousel'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import useScroll from 'src/hooks/window/useScroll'
import isVisible from 'src/utils/isVisible'

// assets
import RequestDigitalAccount from '../../assets/data/RequestUpdateAddress.json'
import RequestDigitalAccountMEI from '../../assets/data/RequestUpdateAddressMEI.json'

// Style
import { ImgSizeCarousel, TextCarousel, HowToRequestSection } from './style'
import usePageQuery from './pageQuery'

type RequestDigitalAccountProps= {
  question: string;
  title: string;
  description?: string;
  image: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const CarouselAtualizarEndereco = (props: {page: string}) => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const [ imageIndex, setImageIndex ] = useState(0)
  let RequestDigitalAccountJSON = RequestDigitalAccount

  if (props.page === 'MEI') {
    RequestDigitalAccountJSON = RequestDigitalAccountMEI
  }

  const imageNames = [
    'AcessoAcesseSuaConta',
    'AcessoAbraOmenuDoApp',
    'AcessoAcessarContaMei',
    'AcessoSelecioneAconta',
  ]

  const images = useStaticQuery(graphql`
    query {
      AcessoAcesseSuaConta: imageSharp(fluid: {originalName: { regex: "/acesso-acesse-sua-conta/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      AcessoAbraOmenuDoApp: imageSharp(fluid: {originalName: { regex: "/acesso-abra-o-menu-do-app/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      AcessoAcessarContaMei: imageSharp(fluid: {originalName: { regex: "/acesso-acessar-conta-mei/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      AcessoSelecioneAconta: imageSharp(fluid: {originalName: { regex: "/acesso-selecione-a-conta/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  function changeImage (nextSlide: number) {
    setImageIndex(nextSlide)
  }

  // Refs
  const carouselRef = useRef<HTMLElement>(null)

  // Animations
  const [ animatedCarouselImage, setAnimatedCarouselImage ] = useSpring(() => (translateFrom))
  const [ animatedCarousel, setAnimatedCarousel ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(carouselRef.current, -0.3)) {
      setAnimatedCarouselImage((translateTo))
    }
    if (isVisible(carouselRef.current, -0.3)) {
      setAnimatedCarousel((fadeTo))
    }
  }, [ scroll ])

  const rightArrow = ({ onClick }: ArrowProps) => (
    <button
      onClick={ onClick } data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: ArrowProps) => (
    <button
      onClick={ onClick } data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <ArrowLeft width={40} height={40} color='orange--base' />
    </button>
  )

 return (
   <HowToRequestSection ref={carouselRef} className='bg-orange--extra py-xl-5'>
     <div className='container py-5'>
       <div className='row align-items-center'>
         <div className='col-12 col-md-6 col-lg-5 pr-xl-0'>
           <DefaultCarousel
             sm={{ items: 1 }}
             md={{ items: 1 }}
             lg={{ items: 1 }}
             xl={{ items: 1 }}
             customLeftArrow={leftArrow}
             customRightArrow={rightArrow}
             beforeChange={(nextSlide: number) => changeImage(nextSlide)}
           >
             { RequestDigitalAccountJSON.map((item: RequestDigitalAccountProps) => (
               <Fragment key={item.title}>
                 <TextCarousel>
                   <animated.h2
                     style={animatedCarousel[0]}
                     className='fs-20 fs-md-24 fs-lg-20 fs-xl-24 lh-25 lh-md-30 lh-lg-25 lh-xl-30 pb-2 pb-md-3 text-white question'
                   >{item.question}
                   </animated.h2>
                   <animated.p
                     style={animatedCarousel[0]}
                     className='fs-24 lh-30 fs-md-20 fs-lg-32 fs-xl-40 lh-md-25 lh-lg-40 lh-xl-50 text-white fw-600'
                   >{item.title}
                   </animated.p>
                   <animated.p
                     style={animatedCarousel[0]}
                     className='fs-18 lh-22 text-white'
                   >{item.description}
                   </animated.p>
                 </TextCarousel>
                 <animated.div
                   style={animatedCarouselImage}
                   className='col-12 col-md-6 col-lg-5 offset-lg-1 d-md-none pt-4 pb-3 py-md-0'
                 >
                   <ImgSizeCarousel>
                     <Img fluid={data[item.image].fluid} alt={item.title} />
                   </ImgSizeCarousel>
                 </animated.div>
               </Fragment>
                ),
              )}
           </DefaultCarousel>
         </div>
         <animated.div style={animatedCarouselImage} className='col-12 col-md-6 col-lg-5 offset-lg-2 d-none d-md-block'>
           <ImgSizeCarousel>
             <Img fluid={images[imageNames[imageIndex]].fluid} alt='Telas de atualização de endereço' />
           </ImgSizeCarousel>
         </animated.div>
       </div>
     </div>
   </HowToRequestSection>
 )
}

CarouselAtualizarEndereco.defaultProps = {
  page: 'PF',
}

export default CarouselAtualizarEndereco
