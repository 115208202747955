import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const HowToRequestSection = styled.section`
  h2 {
    + p {
      font-family: 'Sora';
    }
  }

  .react-multi-carousel-track {
    padding: 0 30px 30px 0;

    @media ${device.tablet} {
     padding-bottom: 50px;
    }
  }

  .react-multi-carousel-dot-list .react-multi-carousel-dot { 
    button {
      background-color: white;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -10px;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .question {
    @media ${device.desktopXL} {
      font-size: 24px;
    }
  }

  button {
    svg {
      fill: white;
    }
  }
`

export const TextCarousel = styled.div`
  min-height: 179px !important;
`

export const ImgSizeCarousel = styled.div`
  .gatsby-image-wrapper {

    @media ${device.desktopXXL} {
      width: 456px;
    }
  }
`
