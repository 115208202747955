import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      AcessoAcesseSuaConta: imageSharp(fluid: {originalName: { regex: "/acesso-acesse-sua-conta/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AcessoAbraOmenuDoApp: imageSharp(fluid: {originalName: { regex: "/acesso-abra-o-menu-do-app/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AcessoAcessarContaMei: imageSharp(fluid: {originalName: { regex: "/acesso-acessar-conta-mei/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      AcessoSelecioneAconta: imageSharp(fluid: {originalName: { regex: "/acesso-selecione-a-conta/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
