import React, { useState } from 'react'

// Components
import { Modal } from 'src/components/Modal'
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import AtivacaoModal1 from 'src/components/UI/Forms/InterAtivacaoClientesForm1'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

// Sections
import Header from './sections/header/_index'
import CoberturaEntrega from './sections/NaoLembraOsDados/_index'
import AtualizarEndereco from './sections/atualizarEndereco/_index'
import HelpCenter from '../helpCenter/_index'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'

const Cartao = () => {
  const page = 'PF'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ openModal1, setOpenModal1 ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  const openAccountPJFormModal1 = domReady && (
    <Modal isModalOpen={openModal1} setIsModalOpen={setOpenModal1} locationToRender={document.body}>
      <AtivacaoModal1 closeModal={() => setOpenModal1(false)} page={page} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        {openAccountPJFormModal1}
        <Header page={page} setOpenModal1={setOpenModal1} />
        <CoberturaEntrega />
        <AtualizarEndereco />
        <HelpCenter />
      </Layout>
    </Wrapper>
  )
}

export default Cartao
